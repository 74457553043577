function leaveTransition() {
    let app = document.getElementById('app');
    let loader = document.querySelector('.main-loader');
    
    if(app.classList.contains("transition-enter")) app.classList.remove("transition-enter");
    app.classList.add("transition-leave");
    loader.classList.add('visible');
}

function enterTransition() {
    let app = document.getElementById('app');
    let loader = document.querySelector('.main-loader');
    if(app.classList.contains("transition-leave")) app.classList.remove("transition-leave");
    app.classList.add("transition-enter");
    if(loader.classList.contains('visible')) loader.classList.remove('visible');
}

const transition_length = 1000;

export {
    leaveTransition,
    enterTransition,
    transition_length
}