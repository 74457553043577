<template>
    <div class="home">
        <ParallaxImage
            ref="bgImg"
            :src="backgroundSrc"
            class="bg-img"
            :parallax="imageParallax"
            @onload="enterTransition"
        />

        <FloatingTitle
            :text="title"
            :fontSize="titleFontSize"
        />

        <component v-bind:is="html" class="content">
        </component>
    </div>
</template>

<script>
    import * as Services from '../js/Services'
    import FloatingTitle from '../components/FloatingTitle'
    import ParallaxImage from '../components/ParallaxImage'
    import * as Api from '../js/Api'
    import * as Transition from '../js/Transition'

    export default {
        name: "Home",
        components: {
            FloatingTitle,
            ParallaxImage
        },
        props: {
            transitionTimeout: null
        },
        data() {
            return {
                html: null,
                backgroundSrc: null,
                title: null,
                titleFontSize: 12,
                imageParallax: false
            }
        },
        created() {
            Api.getPage(1)
                .then(response => {
                    this.html = { template: '<div>' + response.data.data.body + '</div>'};
                    
                    const settings = response.data.data.settings;
                    this.backgroundSrc = settings.find(item => item.name == 'home_page_background').value;
                    this.title = settings.find(item => item.name == 'home_page_title').value;
                    this.titleFontSize = settings.find(item => item.name == 'home_page_title_font_size').value;
                    this.imageParallax = settings.find(item => item.name == 'home_page_parallax_on').value;

                    Services.changeNavTitle(response.data.data.title);
                })
                .catch(err => {
                    this.$refs.bgImg.$el.style="height: 0"
                    this.html = {
                        template: `<div class="error">${err}</div>`
                    }
                });
        },
        methods: {
            enterTransition() {
                Transition.enterTransition();
            }
        },
        computed: {
            customTransition: () => true
        }
    }
</script>

<style lang="scss">
    @import "../styles/vars.scss";

    .loader.home-loader {
        position: absolute;
        z-index: 0;
    }

    .home .bg-img {
        margin: 0;
        width: 100vw;
        height: 100vh;
    }

    .home .content {
        background-color: var(--bg);
        min-height: 50vh;
        position: relative;
        z-index: 2;
        padding: 0 4em;
        
        &.columns {
            display: flex;
            justify-content: space-evenly;
            flex-direction: row;

             .column {
                width: 50%;
            }

            .column:first-of-type {
                    margin-right: 2em;
            }
        }   

        a {
            text-decoration: underline;
        }
   }

   @media screen and (max-width: 800px) {
       .home .content {
            flex-direction: column;
            padding: 0 1.5em;

            .column {
                width: 100%;
            }
       }
   }

</style>