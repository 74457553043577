import axios from "axios";

const apiCall = (param) => {
	const baseUrl = process.env.NODE_ENV !== 'development'
      ? process.env.VUE_APP_PRODUCTION_API_URL
      : process.env.VUE_APP_LOCAL_API_URL;
	
    return `${baseUrl}${param}`;
}

const getMenu = () => {
    return axios.get(apiCall('menu'));
}

const getPage = (id) => {
    return axios.get(apiCall(`pages/${id}`));
}

const getFile = (fileId) => {
    return axios.get(apiCall(`files/${fileId}`));
}

const getSettings = () => {
    return axios.get(apiCall('settings'));
}

const downloadFile = (fileId, password) => {
    return axios.post(apiCall(`files/${fileId}/download`), { password: password }, { responseType: 'blob' });
}

export {
    getMenu,
    getPage,
    getFile,
    getSettings,
    downloadFile
}