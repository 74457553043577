import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    linkActiveClass: 'is-active',
    routes: [
        {
            path: '/',
            redirect: '/home',
        },
        {
            path: '/page/1',
            redirect: '/home',
        },
        {
            path: '/page/2',
            redirect: '/about',
        },
        {
            path: '/home',
            name:'home',
            components: {
                default: require('../views/Home').default
            }
        },
        {
            path: '/about',
            name: 'about',
            components: {
                default: require('../views/About').default
            }
        },
        {
            path: '/page/:id',
            name: 'page',
            components: {
                default: require('../views/Page').default
            }
        }
    ],
    scrollBehavior (to, from, savedPosition) {
        let position = { x: 0, y: 0 }
        // Keep scroll position when using browser buttons
        if (savedPosition) {
          position = savedPosition
        }
    
        // Workaround for transitions scrolling to the top of the page
        // However, there are still some problems being fixed by the vue team
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(position)
          }, 1000)
        })
      }
      
});

export default router