<template>
    <nav>
		<div ref="bar" class="bar">
			<div class="nav-start">
				<div
                    class="bar-title"
                    ref="title"
                    @click="toggleMenu" 
                    @mouseenter="hoverBurgerAndTitle"
                    @mouseleave="() => hoverBurgerAndTitle(false)"
                >
                    Current page
                </div>
			</div>
			<div class="nav-end">
                <a
                    v-for="(navbarItem, id) in navbarItems"
                    :key="id"
                    :href="getNavbarHref(navbarItem)"
                    v-html="navbarItem.text"
                />
			</div>
		</div>
		<div 
            class="burger"
            ref="burger"
            @click="toggleMenu"
            @mouseenter="hoverBurgerAndTitle"
            @mouseleave="() => hoverBurgerAndTitle(false)"
        >
			<span />
			<span />
			<span />
		</div>
		<div ref="menu" class="menu">
            <div class="menu-items">
                <div v-for="(menuItem, id) in menuItems" :key="id" class="link">
                    <router-link 
                        v-if="menuItem.type == 1"
                        :to="{ 
                            name: menuItem.value == 1 ? 'home' :
                                    menuItem.value == 2 ? 'about' : 'page',
                            params: [1, 2].includes(menuItem.value) ? {} : { id: menuItem.value }
                        }"
                        :class="{
                            'nested': menuItem.nested,
                            'current': isCurrentPage(menuItem)
                        }"
                        :style="`font-size: ${menuFontSize}em`"
                    >
                        {{ menuItem.text }}
                    </router-link>
                    <a 
                        v-else
                        :href="menuItem.value"
                        :class="{
                            'nested': menuItem.nested
                        }"
                        :style="`font-size: ${menuFontSize}em`"
                    >
                        {{ menuItem.text }}
                    </a>
                </div>
            </div>
            <div class="menu-navbar-items">
                <a
                    v-for="(navbarItem, id) in navbarItems"
                    :key="id"
                    :href="getNavbarHref(navbarItem)"
                    v-html="navbarItem.text"
                    class="link"
                />
            </div>
		</div>
        <div ref="menu_bg" class="menu-bg" />
    </nav>
</template>

<script>
    import * as Api from '../js/Api';
    import * as Services from '../js/Services'

    export default {
        name: "HeadContainer",
        data() {
            return {
                menuItems: null,
                navbarItems: null,
                menuFontSize: {
                    type: [Number, String],
                    default: 1.75
                }
            }
        },
		methods: {
            getNavbarHref(navbarItem) {
                if (+navbarItem.type === 1) {
                    switch (+navbarItem.value) {
                        case 1:
                            return '/#/home';
                        case 2:
                            return '/#/about';
                        default:
                            return `/#/page/${navbarItem.value}`;
                    }
                }

                return navbarItem.value;
            },
			toggleMenu() {
				this.$refs.burger.classList.toggle('open');
				this.$refs.menu.classList.toggle('open');
				this.$refs.bar.classList.toggle('hidden');
			},
            toggleBg(type, toggle) {
                let el = this.$refs.menu_bg;
                if(toggle) {
                    el.classList.add('visible');
                    el.classList.add(`type${type}`);
                } else {
                    el.classList.remove('visible');
                    el.classList.remove(`type${type}`);
                }
            },
            retrieveSettings() {
                if(!localStorage.getItem('title')) return null;
                return {
                    favicon: localStorage.getItem('favicon'),
                    theme: localStorage.getItem('theme'),
                    title: localStorage.getItem('title'),
                    description: localStorage.getItem('description')
                };
            },
            storeSettings(settings) {
                for(const property in settings)
                    localStorage.setItem(property, settings[property]);
            },
            setSettings() {
                var settings = this.retrieveSettings();
                if(!settings) return;
                document.body.classList.remove("theme--dark");
                document.body.classList.add(`theme--${settings.theme}`);
                document.title = settings.title;
                var desc = document.querySelector('meta[name="description"]');
                if(desc) desc.setAttribute("content", settings.description);
                else {
                    desc = document.createElement('meta');
                    desc.name = "description";
                    desc.content = settings.description;
                    document.getElementsByTagName('head')[0].appendChild(desc);
                }
                Services.changeFavicon(settings.favicon);
            },
            isCurrentPage(menuItem) {
                const route = this.$route;
                if(route.name == 'home' && menuItem.value == '1') return true;
                if(route.name == 'about' && menuItem.value == '2') return true;
                return route.params?.id == menuItem.value;
            },
            hoverBurgerAndTitle(hover = true) {
                this.$refs.burger.classList[hover ? 'add' : 'remove']('hover');
                this.$refs.title.classList[hover ? 'add' : 'remove']('hover')
            },
		},
        async created() {
            document.body.classList.add("theme--dark");
            this.setSettings();
            Api.getSettings()
                .then(response => {
                    let data = response.data.data;
                    this.menuFontSize = data.find(item => item.name == 'menu').value.fontSize;
                    this.storeSettings({
                        favicon: data.find(item => item.name == 'favicon').value,
                        theme: data.find(item => item.name == 'theme').value,
                        title: data.find(item => item.name == 'meta').value.title,
                        description: data.find(item => item.name == 'meta').value.description,
                    })
                    this.setSettings();
                })
                .catch(err => console.error(err));

            try {
                let menuResponse = await Api.getMenu();

                this.menuItems = menuResponse.data.data[0].menuItems.map(el => 
                    [el, el.children.map(child => { return {...child, nested: true}})]
                    .flat()).flat();
                this.navbarItems = menuResponse.data.data[1].menuItems;
            } catch(err) {
                console.error(err);
            }

            await this.$nextTick();

            [...document.querySelectorAll('.link a')].forEach((el, id) => {
                const toggleBgType = (toggle) => {
                    this.toggleBg(id % 4 + 1, toggle);
                }
                el.onmouseenter = () => { toggleBgType(true) };
                el.onmouseout = () => { toggleBgType(false) };
            });
        },
		watch: {
			'$route'() {
				if(this.$refs.burger.classList.contains('open')) this.toggleMenu();
			}
		}
    }
</script>

<style lang="scss">
    @import "../styles/main.scss";

	nav {
        font-family: "Radikal WUT";
        font-size: 1.2em;
		z-index: 3;
        position: absolute;
	}

    .bar {
        top: 1.8em;
        width: 85vw;
        position: absolute;
        left: 7em;
        display: flex;
        justify-content: space-between;
		transition: opacity 0.2s ease;
    }

	.hidden {
		opacity: 0;
	}

    .nav-end {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        font-size: inherit;
        font-family: inherit;

        a {
            margin: 0 2em;
        }
    }

    .nav-start {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        font-size: inherit;
        font-family: inherit;

        .bar-title {
            font-size: inherit;
            font-family: inherit;
            transition: color 0.4s cubic-bezier(0.77,0.2,0.05,1.0);
            cursor: pointer;
        }
    }

    .menu-bg {
        display: none;
        animation: animatedBackground 50s linear infinite;
        z-index: 3;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-repeat: repeat;
        background-color: rgba(60, 60, 76, 0.7);

        &.visible {
            display: unset;
        }

        &.type1 {
            background-image: url(~@/assets/menu-arrows-1.png);
        }

        &.type2 {
            background-image: url(~@/assets/menu-arrows-2.png);
        }

        &.type3 {
            background-image: url(~@/assets/menu-arrows-3.png);
        }

        &.type4 {
            background-image: url(~@/assets/menu-arrows-4.png);
        }
    }

    @keyframes animatedBackground {
        from {
            background-position: 0 0;
        }

        to {
            background-position: 100vw 0;
        }
    }

	/* Menu */

    .menu::-webkit-scrollbar {
        width: 0;
    }

	.menu {
		position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 30em;
        overflow-y: scroll;
        scrollbar-width: none;
		transform: translate(-100%, 0);
		transition: transform 0.2s cubic-bezier(0.77,0.2,0.05,1.0);
		display: flex;
		flex-direction: column;
        justify-content: space-between;
		z-index: 4;
        padding-right: 5%;

        .menu-items {
            display: flex;
            flex-direction: column;

            > :first-child { 
                padding-top: 12vh;
            }

            > :last-child {
                padding-bottom: 15%;
            }

        }

		.link {
            display: inline-block;
			margin: 0.6em 70px;

            &:nth-of-type(4n+1) a:hover {
                color: $sunny;
            }

            &:nth-of-type(4n+2) a:hover {
                color: $mint;
            }

            &:nth-of-type(4n+3) a:hover {
                color: $apricot;
            }

            &:nth-of-type(4n) a:hover {
                color: $sapphire;
            }
		}

        .nested {
            position: relative;
            margin-top: 0.5em;
            left: 2em;
        }

        .current {
            &::before {
                font-family: "Material Icons";
                content: "\e5c8";
                top: 0.15em;
                position: relative;
            }
        }

        .menu-navbar-items {
            display: none;
            font-size: 0.6em;
            margin-bottom: 3em;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
        }
	}

    

	.menu.open {
		transform: translate(0, 0);
	}

    /* Burger */

	.burger {
		position: fixed;
        font-size: 1.75em;
		left: 70px;
		top: 1em;
		cursor: pointer;
		width: 33px;
		height: 33px;
		z-index: 5;
	}

    .burger span {
        display: block;
        width: 33px;
        height: 2px;
        margin-bottom: 10px;
        position: relative;
        
        border-radius: 3px;
        
        transition: transform 0.4s cubic-bezier(0.77,0.2,0.05,1.0),
					opacity 0.45s ease,
                    background 0.4s cubic-bezier(0.77,0.2,0.05,1.0);
        }

        .burger span:first-child {
            transform-origin: 0% 0%;
        }

        .burger span:nth-last-child(1) {
            margin-bottom: 0;
            transform-origin: 0% 100%;
        }

        /* 
        * Transform all the slices of hamburger
        * into a crossmark.
        */
        .burger.open > span {
            opacity: 1;
            transform: translate(1px, 1px) rotate(45deg);
        }

        /*
        * But let's hide the middle one.
        */
        .burger.open > span:nth-last-child(2) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
        }

        /*
        * Ohyeah and the last one should go the other direction
        */
        .burger.open > span:nth-last-child(1) {
            transform: rotate(-45deg);
        }

    @media screen and(max-width: 800px) {
        .menu {
            width: 100%;
        }

        .bar {
            left: 5em;
            .nav-end {
                display: none;
            }
        }

        .burger {
            left: 35px;
        }

        .menu-bg.visible {
            display: none;
        }

        .menu {
            .link {
                margin-left: 35px;
            }

            .nested {
                left: 1em;
            }

            .menu-navbar-items {
                display: flex;
            }
        }
    }
</style>