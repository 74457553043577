<template>
    <div v-if="loaded" class="about">
        <div class="content">
            <div class="column">
                <ParallaxImage
                    :src="imgSrc"
                    class="portrait-img"
                    noTranslate
                    border
                    @onload="enterTransition"
                />
            </div>
            <div class="column">
                <component v-bind:is="html" class="inner-content" />
            </div>
        </div>
    </div>
    <div v-else>
        <div class="loader visible about-loader">
            <div class="progress circular"/>
        </div>
    </div>
</template>

<script>
    import * as Services from '../js/Services'
    import ParallaxImage from '../components/ParallaxImage'
    import * as Api from '../js/Api'
    import * as Transition from '../js/Transition'

    export default {
        name: "About",
        components: {
            ParallaxImage
        },
        data() {
            return {
                html: null,
                imgSrc: null,
                title: null,
                loaded: false
            }
        },
        created() {
            Api.getPage(2)
                .then(response => {
                    this.html = { template: '<div>' + response.data.data.body + '</div>'};
                    this.imgSrc = response.data.data.settings[0].value;
                    this.title = response.data.data.title;
                    Services.changeNavTitle(response.data.data.title);
                    this.loaded = true;
                })
                .catch(err => {
                    this.html = {
                        template: `<div class="error">${err}</div>`
                    }
                });
        },
        methods: {
            enterTransition() {
                Transition.enterTransition();
            }
        },
        computed: {
            customTransition: () => true
        }
    }
</script>

<style lang="scss" scoped>
    @import '../styles/vars.scss';
    @import "../styles/loader.scss";

    .loader.about-loader {
        position: absolute;
        z-index: 0;
    }

    .portrait-img ::v-deep {
        width: 20em;
        height: 30em;
        margin-left: auto;
        margin-right: 5em;

        img {
            width: inherit;
        }
    }

    .content ::v-deep {
        min-height: 30vh;
        background-color: var(--bg);
        position: relative;
        z-index: 2;
        padding: 10em;
        display: flex;
        justify-content: space-evenly;

        .column {
            width: 50%;
        }

        .column:first-of-type {
                margin-right: 2em;
        }

        a {
            text-decoration: underline;
        }
   }

   @media screen and (max-width: 1100px) {
       .content {
            padding: 5em 2em 2em 2em;

            .column:first-of-type {
                width: 20%;
            }
       }

       .portrait-img {
            width: 10em;
            height: 15em;
            top: 20%;
            margin-left: auto;
            margin-right: auto;
        }
   }

   @media screen and (max-width: 700px) {
       .content {
            flex-direction: column;

            .column {
                width: 100%;

                &:first-of-type {
                    width: 100%;
                }
            }
       }
   }

</style>