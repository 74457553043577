var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('div',{ref:"bar",staticClass:"bar"},[_c('div',{staticClass:"nav-start"},[_c('div',{ref:"title",staticClass:"bar-title",on:{"click":_vm.toggleMenu,"mouseenter":_vm.hoverBurgerAndTitle,"mouseleave":function () { return _vm.hoverBurgerAndTitle(false); }}},[_vm._v(" Current page ")])]),_c('div',{staticClass:"nav-end"},_vm._l((_vm.navbarItems),function(navbarItem,id){return _c('a',{key:id,attrs:{"href":_vm.getNavbarHref(navbarItem)},domProps:{"innerHTML":_vm._s(navbarItem.text)}})}),0)]),_c('div',{ref:"burger",staticClass:"burger",on:{"click":_vm.toggleMenu,"mouseenter":_vm.hoverBurgerAndTitle,"mouseleave":function () { return _vm.hoverBurgerAndTitle(false); }}},[_c('span'),_c('span'),_c('span')]),_c('div',{ref:"menu",staticClass:"menu"},[_c('div',{staticClass:"menu-items"},_vm._l((_vm.menuItems),function(menuItem,id){return _c('div',{key:id,staticClass:"link"},[(menuItem.type == 1)?_c('router-link',{class:{
                            'nested': menuItem.nested,
                            'current': _vm.isCurrentPage(menuItem)
                        },style:(("font-size: " + _vm.menuFontSize + "em")),attrs:{"to":{ 
                            name: menuItem.value == 1 ? 'home' :
                                    menuItem.value == 2 ? 'about' : 'page',
                            params: [1, 2].includes(menuItem.value) ? {} : { id: menuItem.value }
                        }}},[_vm._v(" "+_vm._s(menuItem.text)+" ")]):_c('a',{class:{
                            'nested': menuItem.nested
                        },style:(("font-size: " + _vm.menuFontSize + "em")),attrs:{"href":menuItem.value}},[_vm._v(" "+_vm._s(menuItem.text)+" ")])],1)}),0),_c('div',{staticClass:"menu-navbar-items"},_vm._l((_vm.navbarItems),function(navbarItem,id){return _c('a',{key:id,staticClass:"link",attrs:{"href":_vm.getNavbarHref(navbarItem)},domProps:{"innerHTML":_vm._s(navbarItem.text)}})}),0)]),_c('div',{ref:"menu_bg",staticClass:"menu-bg"})])}
var staticRenderFns = []

export { render, staticRenderFns }