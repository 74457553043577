const throttle = (type, name) => {
    let running = false;
    let func = function(){
      if (running){ return; }
      running = true;
      requestAnimationFrame(function(){
        window.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };
    window.addEventListener(type, func);
};

const init = () => {
    window.addEventListener(
        'load',
        () => {
            setTimeout(() => {  
                document.querySelector('html').style="overflow-y: auto";
                document.querySelector('.loading').style="opacity: 0";
                document.getElementById('app').classList.add('loaded');
                setTimeout(() => { window.dispatchEvent(new CustomEvent('scroll')); }, 200); }, 1000);
        },
        false
    );

    //if(document.readyState === 'complete') setTimeout(() => { window.dispatchEvent(new CustomEvent('scroll')); }, 200);

    throttle("scroll", "optimizedScroll");
}

const changeFavicon = (href) => {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = href;
}

const changeNavTitle = (title) => {
    document.querySelector('.bar-title').innerHTML = title;
}

export {
    init,
    changeNavTitle,
    changeFavicon, 
}