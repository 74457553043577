<template>
    <div class="floating-title">
        <div
        ref="title" 
            :class="{
                'title': true,
                'animated1': animated,
                'static': !animated
            }"
            :style="`font-size: ${fontSize}vmax`"
        >
            {{ text }}
        </div>
        <div
            v-if="animated"
            class="title animated2"
            :style="`font-size: ${fontSize}vmax`"
        >
            {{ text }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "FloatingTitle",
        props: {
            text: String,
            animated: Boolean,
            fontSize: {
                type: [Number, String],
                default: 12
            }
        }
    }
</script>

<style lang="scss">
 
//animations

.title {
    font-family: "Radikal WUT";
    top: calc(50vh - 0.75em);
    position: absolute;
    white-space: nowrap;
    line-height: 1em;

    &.static {
        top: 0;
        left: 5vw;
        width: 90vw;
        height: 100vh;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        white-space: normal;
        text-shadow: 0 0 transparent, 0 7px var(--bg), 0 0 transparent, 0 0 transparent;
    }
}

.title.animated1 {
    animation: floatText1 15s infinite linear;
}

.title.animated2 {
    animation: floatText2 15s infinite linear;
}

@keyframes floatText1 {
    from {
        transform: translateX(25vw);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes floatText2 {
    from {
        transform: translateX(calc(50vw + 100%));
    }

    to {
        transform: translateX(25vw);
    }
}

</style>