<template>
    <footer>
        <div class="footer-text">
            Made by KNI dev team
        </div>
    </footer>
</template>

<script>
    export default {
        name: "FooterContainer"
    }
</script>

<style lang="scss">

    footer {
        padding-top: 3em;
        margin: auto;
        text-align: center;
        height: 4em;
    }
    
</style>