<template>
    <div :class="{'parallax-image-container': true, 'parallax-image-border': border}">
        <div class="parallax-image">
            <img ref="image" :src="src"/> 
        </div>
    </div>
</template>

<script>
    export default {
        name: "ParallaxImage",
        props: {
            src: String,
            noTranslate: {
                type: Boolean,
                default: false
            },
            border: {
                type: Boolean,
                default: false
            },
            parallax: {
                type: Boolean,
                default: true
            },
        },
        methods: {
            throttle(ms, callback) {
                let lastCall=0;

                return function() {
                    var now = new Date().getTime(),
                        diff = now - lastCall;
                    if (diff >= ms) {
                        lastCall = now;
                        callback.apply(this, arguments);
                    }
                };
            },
            onScroll() {
                if(!this.$props.parallax) {
                    window.removeEventListener("scroll", this.onScroll);
                    return;
                }
                let scrolltotop = document.scrollingElement.scrollTop;
                let yvalue = scrolltotop * 0.5;
                this.$refs.image.style = `transform: ${this.$props.noTranslate ? `` : `translateY(${yvalue}px)`} scale(${Math.max(1, 1 + yvalue * 0.001)})`;  
            }
        },
        mounted() {
            window.addEventListener("scroll", this.onScroll);
            this.$refs.image.onload = () => this.$emit('onload');
        },
        beforeDestroy() {
            if(this.$props.parallax) window.removeEventListener("scroll", this.onScroll);
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/vars.scss";

    .parallax-image-container {
        position: relative;
        margin: 2em;
        justify-self: center;
        width: inherit;
        height: inherit;

        &.parallax-image-border::before {
            content: "";
            position: absolute;
            left: -15px;
            top: 15px;
            width: 100%;
            height: 100%;
            border-left: 1px solid var(--text-border);
            border-bottom: 1px solid var(--text-border);
        }

        .parallax-image {
            width: inherit;
            height: inherit;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: transform 1s $smooth-bezier;
            }
        }      
    }

</style>