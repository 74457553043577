<template>
    <main class="hero-body">
        <div class="loading">Loading...</div>
        <div class="loader main-loader">
            <div class="progress circular"/>
        </div>
        <transition 
            mode="out-in"
            v-on:enter="enter"
            v-on:leave="leave"
        >
            <router-view :key="$route.fullPath" ref="routerView"/>
        </transition>
        <div class="cookies-info">
            <div>
                W naszej witrynie wykorzystujemy pliki cookies (<a href="https://www.pw.edu.pl/Polityka-dotyczaca-cookies">dowiedz się więcej</a>).
            </div>
            <div>
                <a @click="hideCookiesInfo">Akceptuję cookies</a>
            </div>
            <div>
                <a @click="hideCookiesInfo">Zamknij</a>
            </div>
        </div>
    </main>
</template>

<script>
    import * as Transition from '../js/Transition.js'
    import * as Services from '../js/Services.js'

    export default {
        name: "BodyContainer",
        methods: {
            leave: function (el, done) {
                Transition.leaveTransition();
                setTimeout(done, Transition.transition_length);
            },
            enter: function (el, done) {
                if(this.$refs.routerView.customTransition === true) {
                    return done();
                }
                Transition.enterTransition();
                setTimeout(done, Transition.transition_length);
            },
            hideCookiesInfo() {
                document.querySelector('.cookies-info').classList.add('cookies-hidden');
                localStorage.setItem('cookies-info', 'seen');
            }
        },
        mounted() {
            if(localStorage.getItem('cookies-info')) this.hideCookiesInfo();
            Services.init();
        }
    }
</script>

<style lang="scss">
    @import "../styles/vars.scss";
    @import "../styles/loader.scss";

    .cookies-info {
        position: fixed;
        bottom: 0;
        width: 80%;
        left: 10%;
        min-height: 2.5em;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: var(--menu-bg);
        z-index: 5;
        border-top: 2px solid var(--nav--active);
        transition: transform 0.5s $smooth-bezier, opacity 2s $smooth-bezier;
        opacity: 1;
        color: var(--menu-text);

        &.cookies-hidden {
            transform: translateY(100%);
            opacity: 0;
        }

        & > div {
            &:not(:last-child) {
                margin-right: 2em;
            }
        }

        a {
            cursor: pointer;
            color: var(--text--active);
            text-decoration: underline;
        }
    }

    .hero-body {
        min-height: 100vh;
    }

    .loading {
        position: absolute;
        width: 100vw;
        top: 50vh;
        text-align: center;
        color: #f3f3f3;
        font-size: 2em;
        z-index: 100;
        transition: opacity 0.5s $smooth-bezier;
        pointer-events: none;
    }

    @media screen and (max-width: 1050px) {
       .cookies-info {
           width: calc(100% - 2em);
           left: 0;
           padding: 1em;
           flex-direction: column;

           & > div {
                &:not(:last-child) {
                    margin-right: 0;
                    margin-bottom: 0.5em;
                }
            }
       }
   }

</style>