<template>
    <div 
        :class="{
            'file': true,
            'tile': $props.tile
        }"
        @click="onClick"
    >
       <span class="material-icons file-icon">attach_file</span>
       <span class="file-name">{{ dataLabel }}</span>
    </div>
</template>

<script>
    import * as Api from '../js/Api'

    export default {
        name: "file",
        data() {
            return {
                hasPassword: false,
                tile: false,
                filename: null
            }
        },
        props: {
            dataId: String,
            dataLabel: String
        },
        created() {
            Api.getFile(this.dataId)
                .then(response => {
                    this.hasPassword = response.data.data.hasPassword;
                    this.filename = response.data.data.originalName;
                })
                .catch(err => console.error(err));
        },
        methods: {
            onClick() {
                let pass = undefined;
                if(this.hasPassword) {
                    pass = prompt("Password:");
                    if(pass === null) return;
                }
                Api.downloadFile(this.dataId, pass)
                    .then(response => {
                        const blob = response.data;
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = this.filename;
                        link.click();
                        URL.revokeObjectURL(link.href);
                    })
                    .catch(err => {
                        alert(err);
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

    .file {
        cursor: pointer;
        display: inline-block;

        .file-icon {
            position: relative;
            top: 0.2em;
        }

        .file-name {
            text-decoration: underline;
        }

        &:hover {
            color: var(--text--active);
        }
    }

</style>