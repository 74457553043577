<template>
    <component v-bind:is="html" class="page-content">
    </component>
</template>

<script>
    import * as Services from '../js/Services.js'
    import * as Api from  '../js/Api.js'
    import File from '../components/File'
    import Vue from 'vue';
    Vue.component('File', File);

    export default {
        name: "Page",
        components: {
            File
        },
        data() {
            return {
                html: '',
            }
        },
        created() {
            Api.getPage(this.$route.params.id)
                .then(response => {
                    this.html = { template: '<div>' + response.data.data.body + '</div>'};
                    Services.changeNavTitle(response.data.data.title);
                })
                .catch(err => {
                    this.html = {
                        template: `<div class="error">${err}</div>`
                    }
                });
        }
    }
</script>

<style lang="scss">
    @import '../styles/vars.scss';

    .page-content {
        padding-top: 30vh;
        margin: 0 4em;

        a {
            text-decoration: underline;
        }
    }

    @media screen and (max-width: 600px) {
       .page-content {
            margin: 0 1.5em;

            img {
                max-width: 90vw;
            }
       }
   }
</style>